import { EntityId } from '../types';
import { GroupId, UserIdModel } from '../types/models';

export const orgstructureEndpoints = {
  /** @deprecated */
  affiliates: () => `/api/orgstructure/affiliates/`,
  adminRolesList: () => '/api/orgstructure/admin_roles/list/',
  adminRolesId: (keycloakId: UserIdModel) => `/api/orgstructure/admin_roles/${keycloakId}/`,
  adminRolesSave: () => '/api/orgstructure/admin_roles/save/',
  bindUsersUpload: () => `/api/orgstructure/bind_users/upload/`,
  bindUsersCheck: () => `/api/orgstructure/bind_users/check/`,
  bindUsersUploadTaskId: (taskId: string) => `/api/orgstructure/bind_users/${taskId}/`,
  department: () => '/api/orgstructure/department/',
  departmentId: (id: EntityId) => `/api/orgstructure/department/${id}/`,
  departmentUpload: () => '/api/orgstructure/department/upload/',
  departmentCheck: () => '/api/orgstructure/department/check/',
  detailed: (groupId: GroupId) => `/api/orgstructure/${groupId}/detailed/`,
  detailedShort: (groupId: GroupId) => `/api/orgstructure/${groupId}/detailed-short/`,
  idMembersItems: (id: string) => `/api/orgstructure/${id}/members/items/`,
  list: () => '/api/orgstructure/list/',
  organization: () => '/api/orgstructure/organization/',
  organizations: () => '/api/orgstructure/organizations/',
  organizationId: (id: EntityId) => `/api/orgstructure/organization/${id}/`,
  organizationFilter: () => '/api/orgstructure/organization/filter/',
  organizationIdOrganizationsPath: (groupId: GroupId) =>
    `/api/orgstructure/organization/${groupId}/organizations_path/`,
  organizationUpload: () => '/api/orgstructure/organization/upload/',
  organizationCheck: () => '/api/orgstructure/organization/check/',
  organizationOrganizations: () => '/api/orgstructure/organization/organizations/',
  organizationMembersAvailable: () => '/api/orgstructure/organization/members/available/',
  membersIdMove: (keycloakId: UserIdModel) => `/api/orgstructure/members/${keycloakId}/move/`,
  membersGroups: () => '/api/orgstructure/members/orgstructure/',
  membersUserIdGroups: (userId: string) => `/api/orgstructure/members/${userId}/orgstructure/`,
  people: () => '/api/orgstructure/people/',
  peopleId: (id: string) => `/api/orgstructure/people/${id}/`,
  peopleList: () => '/api/orgstructure/people/list/',
  /** @deprecated */
  root: () => '/api/orgstructure/root/',
  search: () => `/api/orgstructure/search/`,
  templates: () => `/api/orgstructure/templates/`,
  usersDownload: () => `/api/orgstructure/users/download/`,
  userPath: () => '/api/orgstructure/user/path/',
};
