import { AxiosResponse } from 'axios';

import { endpoints } from '../../endpoints';
import { ServiceRoleAssignee, PaginationParams } from '../../types';
import {
  UserIdModel,
  CreateUpdateEventModel,
  CreateUpdateScheduleSlotModel,
  ConfirmEventParticipationModel,
  EventId,
  EventRequestDecideParams,
  EventGuestsCount,
  EventScheduleSlotId,
  CategoryActiveStatus,
  EventCategoryId,
  CategoryModel,
  PermissionRoleDetailModel,
} from '../../types/models';
import { api } from '../../utils';

export interface EventCategoryParams extends Omit<CategoryModel<EventCategoryId>, 'id'> {
  id?: EventCategoryId;
}

export interface EventsCategoryListParams extends PaginationParams {
  id?: EventCategoryId;
  activeStatus?: CategoryActiveStatus;
  isUsed?: boolean;
}

export type EventRolesParams = {
  eventId: EventId;
  data: ServiceRoleAssignee[];
};

export const createNewEvent = <T>(event: CreateUpdateEventModel) =>
  api.post<T>({
    url: endpoints.events.eventNew(),
    data: event,
  });

export const updateEvent = <T>(id: EventId, data: CreateUpdateEventModel) =>
  api.put<T>({
    url: endpoints.events.eventEntityId(id),
    data,
  });

export const deleteEvent = (id: EventId) =>
  api.delete({
    url: endpoints.events.eventEntityId(id),
  });

export const getEvent = <T>({ eventId }: { eventId: EventId }) =>
  api.get<T>({
    url: endpoints.events.eventEntityId(eventId),
  });

export const copyEvent = <T>({ eventId }: { eventId: EventId }) =>
  api.post<T>({
    url: endpoints.events.eventIdCopy(eventId),
  });

export const createScheduleSlot = <T>(scheduleSlot: CreateUpdateScheduleSlotModel) =>
  api.post<T>({
    url: endpoints.events.activityNew(),
    data: scheduleSlot,
  });

export const updateScheduleSlot = <T>(id: EventId, data: CreateUpdateScheduleSlotModel) =>
  api.put<T>({
    url: endpoints.events.activityEntityId(id),
    data,
  });

export const deleteScheduleSlot = (id: EventId) =>
  api.delete({
    url: endpoints.events.activityEntityId(id),
  });

export const uploadImage = <T>(image: File) => {
  const formData = new window.FormData();

  formData.append('file', image);

  return api.post<T>({
    url: endpoints.filestorage.fileUpload(),
    data: formData,
  });
};

export const confirmEventParticipation = (id: EventId, data: ConfirmEventParticipationModel) =>
  api.post({
    url: endpoints.events.eventEntityIdConfirmParticipation(id),
    data,
  });

export const declineEventParticipation = (id: EventId) =>
  api.post({
    url: endpoints.events.eventIdDeclineParticipation(id),
  });

export const confirmActivityParticipation = (id: EventId, data: ConfirmEventParticipationModel) =>
  api.post({
    url: endpoints.events.activityConfirmParticipation(id),
    data,
  });

export const declineActivityParticipation = (id: EventId) =>
  api.post({
    url: endpoints.events.activityDeclineParticipation(id),
  });

export const removeMemberFromEvent = ({ eventId, userId }: { eventId: EventId; userId: UserIdModel }) =>
  api.delete<void>({
    url: endpoints.events.eventIdMembersUserId(eventId, userId),
  });

export const eventParticipantsInfo = <T>({ eventId }: { eventId: EventId }) =>
  api.get<T>({
    url: endpoints.events.eventIdParticipantsInfo(eventId),
  });

export const inviteUserToEvent = <T>(eventId: EventId, data: { memberUid: UserIdModel }) =>
  api.post<T>({
    url: endpoints.events.eventIdV2InviteUsers(eventId),
    data,
  });

export const cancelInviteUserToEvent = <T>(eventId: EventId, data: { memberUid: UserIdModel }) =>
  api.delete<T>({
    url: endpoints.events.eventIdV2InviteUsers(eventId),
    data,
  });

export const eventRequestDecide = <T>(eventId: EventId, data: EventRequestDecideParams) =>
  api.patch<T>({
    url: endpoints.events.eventIdDecide(eventId),
    data,
  });

export const updateEventGuestsCount = (eventId: EventId, data: EventGuestsCount) => {
  return api.patch<EventGuestsCount>({
    url: endpoints.events.eventIdGuest(eventId),
    data,
  });
};

export const updateScheduleGuestsCount = (eventId: EventScheduleSlotId, data: EventGuestsCount) => {
  return api.patch<EventGuestsCount>({
    url: endpoints.events.activityEntityIdGuest(eventId),
    data,
  });
};

export const saveEventsCategory = (data: EventCategoryParams): Promise<AxiosResponse> =>
  api.post({
    url: endpoints.events.eventsCategories(),
    data,
  });

export const updateEventsCategory = <T>(data: CategoryModel<EventCategoryId>) =>
  api.put<T>({
    url: endpoints.events.eventsCategoriesId(data.id),
    data,
  });

export const getEventsCategory = <T = unknown>({ id }: { id: EventCategoryId }): Promise<AxiosResponse<T>> =>
  api.get({ url: endpoints.events.eventsCategoriesId(id) });

export const saveEventRoles = (params: EventRolesParams) => {
  const { eventId, data } = params;

  return api.post<PermissionRoleDetailModel>({
    url: endpoints.events.eventIdRoles(eventId),
    data,
  });
};
