import type { Rule } from '@vkph/ui';

import { regexpPatterns } from '../regexpPattern';

export * from './date';
export * from './getRestCharacters';
export * from './langRules';
export * from './maxInputLength';
export * from './required';
export * from './url';
export * from './maxMinValue';

export const emailRule: Rule = {
  type: 'email',
  validator: (_, value = '') =>
    !value || regexpPatterns.email.test(value.trim()) ? Promise.resolve() : Promise.reject(),
  message: 'Укажите правильный email',
};

export const getCorrectJSONRule: Rule = {
  validator: (_, value: string) => {
    try {
      JSON.parse(value);
    } catch (e) {
      return Promise.reject(e);
    }

    return Promise.resolve();
  },
  message: 'Некорректный JSON',
};

export const isValueMatchRegexpPattern = (regex: RegExp, message: string): Rule => ({
  validator: (_, value = '') => {
    return regex.test(value?.trim()) ? Promise.reject() : Promise.resolve();
  },
  message,
});
