export const regexpPatterns = {
  urlSlug: /^([\w_-])*$/,
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  phoneNumber: /^\+?[0-9]*$/,
  number: /^\d*$/,
  startsWithLetter: /^[a-zA-Z]/,
  plainText: /^([\w\s_+])*$/,
  // <h1 ...><span color='green'>hello</span></h1>
  // <h1>текст</h1>
  // =>
  // <span color='green'>hello</span>
  // текст
  headerContent: /(?<=<h[1-6][^>]*?>)(.*?)(?=<\/h[1-6]>)/g,
  // <p...><span color='green'>hello</span></p>
  // <p>текст</p>
  // =>
  // <span color='green'>hello</span>
  // текст
  paragraphContent: /(?<=<p[^>]*?>)(.*?)(?=<\/p>)/g,
  // <p><span color='green'>hello</span></p>&nbsp;<p>
  // =>
  // &nbsp;
  nonBreakingSpace: /(&nbsp;)/gi,
  // <div>
  //   <figure class="video">
  //     <video src="youtube.com" />
  //   </figure>
  //   <figure ... class="image image-cropped" ...>
  //     <video src="image.com" />
  //   </figure>
  // </div>
  // =>
  // <figure class="video">
  //   <video src="youtube.com" />
  // </figure>
  // <figure ... class="image image-cropped" ...>
  //   <video src="image.com" />
  // </figure>
  figureMedia:
    /(<figure[^>]*?class="[^"]*?video[^"]*?"[^>]*?>.*?<\/figure>)|(<figure[^>]*?class="[^"]*?image[^"]*?"[^>]*?>.*?<\/figure>)|(<figure[^>]*?class="[^"]*?table[^"]*?"[^>]*?>.*?<\/figure>)/g,
  // <figure class="image">...</figure>
  // =>
  // image
  // <figure class="image image_resized" style="width:50%;">...</figure>
  // =>
  // image
  figureClassAttribute: /(?<=<figure class=")(.*?)(?=")/g,
  // @[Маша](user/61d5b826-7555-47df-8dd3-d0d9f34ca718) добавила новый тег: @[актуальное](tag/61d5b826-7555-47df-8dd3-d0d9f34ca718)
  // =>
  // @[актуальное](tag/61d5b826-7555-47df-8dd3-d0d9f34ca718)
  mentionTags: /@\[.*?]\(tag\/.*?\)/g,
  // @[актуальное](tag/61d5b826-7555-47df-8dd3-d0d9f34ca718)
  // =>
  // актуальное, 61d5b826-7555-47df-8dd3-d0d9f34ca718
  mentionTagNameId: /(?<=@\[).*?(?=])|(?<=tag\/).*?(?=\))/g,
  urlProtocol: '^(https?:\\/\\/)',
  urlPattern:
    '((([a-zа-я\\d]([a-zа-я\\d-]*[a-zа-я\\d])*)\\.)+[a-zа-я]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d@%_.~+]*)*' + // port and path
    '(\\?[;&a-zа-я\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-zа-я\\d_]*)?$',
  contentDisposotion: /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/,
  // attachment; filename="file name.jpg"
  // =>
  // "file name.jpg"
  // attachment; filename*=UTF-8''filename.jpeg
  // => filename.jpg
};
