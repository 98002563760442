import { Layout, UiEmpty } from '@vkph/ui';
import React, { FC, PropsWithChildren, createContext } from 'react';

import { useAbstractStorage } from '../../../hooks';
import { UserIdModel, UserProfileModel } from '../../../types/models';
import { currentProfileStorage } from '../service/current-profile-storage';

export const CurrentProfileContext = createContext<UserProfileModel | null>(null);

interface Props {
  userId?: UserIdModel;
}

export const CurrentProfileProvider: FC<PropsWithChildren<Props>> = (props) => {
  const { children, userId } = props;

  const {
    data: currentProfileData,
    loading: isCurrentProfileLoading,
    error: isCurrentProfileDataError,
    fetchedAtLeastOnce: isCurrentProfileFetchedAtLeastOnce,
  } = useAbstractStorage(currentProfileStorage, {
    autoFetchAndRefetch: Boolean(userId),
    ...(userId && { autoFetchParams: { userId } }),
    cancelPendingRequestOnUnmount: true,
  });

  const isCurrentProfileRefetching = isCurrentProfileLoading && Boolean(currentProfileData);
  const isLoading = isCurrentProfileLoading && !isCurrentProfileRefetching;

  if (isLoading || !userId || !isCurrentProfileFetchedAtLeastOnce) {
    return <Layout.Loading />;
  }

  if (isCurrentProfileDataError || !currentProfileData) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Произошла ошибка при загрузке пользователя' }} />;
  }

  return (
    <CurrentProfileContext.Provider value={currentProfileData}>{children}</CurrentProfileContext.Provider>
  );
};
