import type { SubstitutionId, SubstitutionModel } from './models';
import type { OptionBase } from './option';

export type Substitution = {
  id?: SubstitutionId;
  activities?: { value: string }[];
  assistant: OptionBase;
  substitutionType: string;
  range: Date[];
  isAllActivities: boolean;
};

export type SubstitutionItemForApi = Omit<SubstitutionModel, 'substitute' | 'id'> & {
  id?: SubstitutionId;
};

export const enum SubstitutionType {
  Illness = 'illness',
  Vacation = 'vacation',
  BusinessTrip = 'business_trip',
}
