import { utcToZonedTime } from 'date-fns-tz';
import format from 'date-fns/format';
import formatRelative from 'date-fns/formatRelative';
import ru from 'date-fns/locale/ru';
import setDefaultOptions from 'date-fns/setDefaultOptions';

import { UTCDate } from '../../types/models';
import { getTimeZoneValue } from '../getTimezoneValue';

// Set global locale:
setDefaultOptions({ locale: ru });

type DateCommonType = Date | UTCDate;
type DateTimeFormatType = 'date' | 'time' | 'dateTime';

const formatsMap: Record<DateTimeFormatType, string> = {
  date: 'dd.MM.yyyy',
  time: 'HH:mm',
  dateTime: 'dd.MM.yyyy в HH:mm',
};

export const getValidDate = (date: DateCommonType) => new Date(date);

const getLocalDate = (date: Date) => utcToZonedTime(date, getTimeZoneValue());

export const getFormattedDate = (date?: DateCommonType, type: DateTimeFormatType = 'date') => {
  return date ? format(getLocalDate(getValidDate(date)), formatsMap[type]) : '';
};

export const getFormattedRelativeDate = (date: DateCommonType, otherType: DateTimeFormatType) => {
  const formatRelativeLocale: Record<string, string> = {
    yesterday: `'вчера в' ${formatsMap.time}`,
    today: `'сегодня в' ${formatsMap.time}`,
    tomorrow: `'завтра в' ${formatsMap.time}`,
    lastWeek: formatsMap[otherType],
    nextWeek: formatsMap[otherType],
    other: formatsMap[otherType],
  };

  const locale: Locale = {
    ...ru,
    formatRelative: (token: string) => formatRelativeLocale[token],
  };

  return formatRelative(getLocalDate(getValidDate(date)), new Date(), { locale });
};

export const getFormattedDateRange = (
  since: DateCommonType,
  till: DateCommonType,
  type: DateTimeFormatType,
) => {
  const sinceFormat = format(getLocalDate(getValidDate(since)), formatsMap[type]);
  const tillFormat = format(getLocalDate(getValidDate(till)), formatsMap[type]);

  return `${sinceFormat} – ${tillFormat}`;
};
