import { profilesEndpoints } from '../../../endpoints/profiles';
import { UserProfile } from '../../../types';
import { UserProfileModel } from '../../../types/models';
import { buildEndpointWithQueryParams, abstractStorageFactory } from '../../../utils';
import { ProfilesMainParams } from '../api';

export const getProfileStorage = () => {
  const storage = abstractStorageFactory<UserProfileModel, UserProfile, null, ProfilesMainParams>({
    endpointBuilder: ({ userId, ...params }) =>
      buildEndpointWithQueryParams(profilesEndpoints.main(userId), { ...params }),
    defaultValue: null,
    dataMapper: (data) => {
      const groupPathList = data.groupPath?.split('/');
      const profileGroupId = groupPathList?.at(-1);
      const profileOrganizationId = groupPathList?.at(0);

      return {
        ...data,
        profileGroupId,
        profileOrganizationId,
      };
    },
  });

  return { storage };
};
